.container {
  width: 100%;
}

.modal {
  position: absolute;
  right: 50%;
  top: 25%;
  transform: translate(50%, 0%);
  width: 380px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  /* font-family: var(--font-family); */
  z-index: 201;
}

.modal svg {
  color: var(--color-button-default);
}

.calendar {
  text-align: center;
  width: auto;
  height: auto;
  color: var(--color-text);
  margin: 0 auto;
}


.arrow:hover {
  cursor: pointer;
}


.rightArrow,
.leftArrow {
  height: 30px;
  width: 30px;
  border-radius: 6px;
  background-color: white;
  color:white;
}


.rightArrow {

  padding: 3px;
  float: right;
  transform: rotate(-90deg);
  box-shadow: -1px 0px 5px var(--color-text);
  margin-right: 3px;
}
.leftArrow {

  padding: 6px;
  float: left;
  transform: rotate(90deg);
  box-shadow: 1px 0px 5px var(--color-text);
  margin-left: 3px;
}

.monthYear {
  font-weight: bold;
  margin-bottom: 8px;
  line-height: 20px;
}

.monthYear2 {
  font-size: 12px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 45px);
  height: 250px;
  margin-bottom: 10px;
}

/* .grid2 {
  display: grid;
  grid-template-columns: 20px 20px 20px 20px 20px 20px 20px;
  width: auto;
  height: 142px;
  margin: auto;
  font-size: 10px;
} */

.gridItem {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 6px;
  margin: auto;
  user-select: none;

}

.days:hover {
  cursor: pointer;
  background-color: #fdc36c;
  color: #fff;
  font-weight: bold;
}

.grayedOut {
  color: #b0b2b7;
}

.grayBg {
  background-color: rgb(236, 236, 236);
}

.today {
  color: var(--color-text);
  background-color: transparent; /* Reset background to default */
  border: none; /* Remove border */
  border-radius: 0; /* Remove rounded corners */
}

.selected {
  color: #fff;
  background-color: #66a2d9 !important; /* Selected date styling */
  border: 1px solid #66a2d9 !important;
  font-weight: bold;
  border-radius: 4px;
}

.days {
  background-color: white; /* Default background */
}

.days:hover {
  background-color: white !important; /* Default background */
  color: #000;
  font-weight: bold;
}

.today:hover {
  background-color: white !important;
  color: #000;
  font-weight: bold;
}

/* .eventDates {
  border: 1px solid #fdc36c;
  background-color: white;
  border-radius: 4px;
}

.eventDates:hover {
  background-color: #fdc36c;
  color: #fff;
  font-weight: bold;
} */

.disabled {
  color: rgb(204, 204, 204);
  background-color: none;
}

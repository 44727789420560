.eventHeader {
  margin-top: 35px;
  padding: 0 1em;
    margin-bottom: 16px;
}

.headerEventType {
  font-size: 1.4em;
  font-weight: 600;
  line-height: 1;
}

.sectionTopMargin {
  margin-top: 15px;
}

.buttonsColumn{
  display: flex;
  /* width: 150px; */
  justify-content: flex-end;
}

.buttonsColumn button{
  margin-left: 10px;
}

tbody tr:nth-child(even) {
  background-color: #3a3a3a;
}

/*.refresh-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

th, td {
  text-align: center;
  vertical-align: middle;
}*/

.sectionTopMargin {
  margin-top: 15px;
}

.headerText {
  font-weight: bold;
  font-size: 1.5em;
}

.headerButtonGroup {
  display: flex;
  gap: 10px;
}

.messagePending,
.messageFailed {
  margin: 1.5em 0.5em;
  font-size: 1.1em;
  display: none;
}

.containerOrderConf {
  margin: 1.5em 0.5em;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.orderConf {
  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  /* color: #405355; */
  color: var(--color-surface-darkgray);
}

.orderConfTitle {
  font-size: 1.1em;
}

.orderConfTitle,
.confirmationCode,
.purchaseDate {
  margin-bottom: 0.25em;
}

.refund-button {
  background-color: var(--color-h);

  min-width: 90px;
  /* padding: 0px 15px; */
  /* margin-right: 5px; */
}

.refund-button:hover {
  /* background-color: #fc4040; */
  background-color: var(--color-button-delete-hover);
}

.ada-button {
  background-color: var(--color-n);

  /* padding: 0px 15px; */
  /* margin-right: 5px; */
}

.ada-button:hover {
  /* background-color: #fc4040; */
  background-color: var(--color-button-review-hover);
}

.refund-container {
  display: flex;
  gap: 5px;
}

.containerTicketSum {
  margin: 1.5em 0.5em;
}

/* Missing ticketSummary */
/* Missing containereventId */
/* Missing eventId */
/* Missing containerstartsOn */
/* Missing startsOn */
/* Missing dateSeparator */
/* Missing containerendsOn */
/* Missing endsOn */
/* Missing containerTickets */
/* Missing ticketType */

.containerDateSeparator {
  grid-row: 2;
  grid-column: 2;
  text-align: center;
}

.containerEvent {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ticket:first-of-type::before {
  display: block;
  position: absolute;
  content: " ";
  width: 12px;
  height: 12px;
  /* background-color: #91b6ba; */
  background-color: var(--color-e);
  transform: rotate(45deg);
  top: -5px;
  left: 15px;
  z-index: -1;
}

.ticket {
  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
  position: relative;
  /* grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1em; */
  padding: 10px 10px;
  margin-bottom: 1px;
  color: var(--color-f);
  /* color: var(--color-text-light); */
  flex: auto;
}

.buttonGroups {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-f);
}

.checkedInName {
  flex: auto;
  text-align: right;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.ticketActive {
  /* background-color: #91b6ba; */
  background-color: var(--color-e);
  padding: 0px 2px;
}

.ticketRefunded {
  /* background-color: #e8e8e8; */
  background-color: var(--color-form-bg);
}

.ticketRevoked {
  background-color: var(--color-ticket-revoke-bg);
}

.containerPaymentConf {
  margin: 1.5em 0.5em;
}

.paymentConf {
  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  /* color: #405355; */
  color: var(--color-surface-darkgray);
}

.paymentConfTitle {
  font-size: 1.1em;
}

.paymentConfTitle,
.amountPaid {
  margin-bottom: 0.25em;
}

.amountPaidValue {
  font-weight: bold;
}

.paymentMethod {
  /* margin-top: 1em; */
}

.show {
  display: block !important;
}

.button-style.light,
button.light {
  background-color: var(--color-button-text);
  border: 1px solid var(--color-button-default);
  color: var(--color-button-default);
}

.button-style,
button {
  /* background-color: #243746;
  border: 1px solid #243746; */
  border-radius: 4px;
  color: var(--color-button-text);
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  padding: 6px 12px;
  white-space: nowrap;
}
.button-style,
.close-circle,
.fa,
.fa-stack,
button {
  display: inline-block;
}
[role="button"] {
  cursor: pointer;
}
a,
a.anchor-dark {
  color: var(--color-button-default);
  text-decoration: none !important;
}
a,
pre code,
table {
  background-color: transparent;
}

.dropleft {
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: unset;
  margin: 0;
}
.show {
  display: block !important;
}

.dropdown-menu {
  position: unset;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 0;
  list-style: none;
  font-size: 13px;
  /* background-color: #fff; */
  background-color: var(--color-input-bg);
  /* border: 1px solid #ccc; */
  border: 1px solid var(--color-shadow);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px var(--color-shadow);
  box-shadow: 0 6px 12px var(--color-shadow);
  background-clip: padding-box;
}

.dropdown-menu,
caption,
th {
  text-align: left;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--color-text);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover {
  background-color: var(--color-dropdown-bg-hover);
}

.modalBackdrop {
  position: fixed;
  /* Cover the entire viewport */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-modal-bg-overlay);
  /* Semi-transparent black */
  z-index: 1040;
  /* Ensure it sits above most other content */
  display: flex;
  justify-content: center;
  /* Center the modal horizontally */
  align-items: center;
  /* Center the modal vertically */
}

.specialNeedsModal {
  position: relative;
  /* Ensure it has a stacking context for z-index */
  z-index: 1050;
  /* Above the backdrop */
  /* background-color: #fff; */
  /* Ensure it's visible against the backdrop */
  margin: auto;
  /* Help with centering if not already centered */
  padding: 20px;
  /* Aesthetic padding */
  border-radius: 8px;
  /* Optional: rounded corners */
  /* Adjust width as necessary, possibly with max-width for responsiveness */
}

/* Scan Modal Styles */

.table-container {
  width: 100%;
  /* margin: 20px auto; */
  display: flex;
  justify-content: left;
  overflow-y: auto;
  max-height: 500px;
}

.custom-table {
  width: 95%;
  border-collapse: collapse;
  background-color: var(--color-bg);
  box-shadow: 0 4px 8px var(--color-shadow);
}

.custom-table th,
.custom-table td {
  padding: 12px 20px;
  text-align: left;
}

/* 
.custom-table td {
  padding: 12px 20px;
  text-align: left;
  word-wrap: break-word;
  max-width: 200px;
} */

.custom-table th {
  background-color: var(--color-card-bg);
  letter-spacing: 0.05em;
  border-bottom: 1px solid var(--color-border);
  font-weight: 700;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: var(--color-custom-table-bg-odd);
}

.custom-table tbody tr:nth-child(even) {
  background-color: var(--color-custom-table-bg-even);
}

.noteArea {
  margin-top: 12px;
}

.scansContainer {
  margin: 0 0 0 10px;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scan {
  width: 100%;
  color: var(--color-scan);
}

.session {
  margin-left: 25px;
}

.location {
  margin-left: 50px;
}

.station {
  margin-left: 75px;
}

.scannedOn {
  margin-left: 100px;
}

.refundConfirmationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.refundConfirmationText {
  font-weight: 500;  
}
.refundConfirmationButton {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
}

.responseAlertError {
  color: var(--color-text-error);
  background-color: var(--color-background-color-error);
  border-color: var(--color-border-error);
}

.response {
  width: 100%;
}

.responseAlerts {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
  margin: 0 !important;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 16px;
  min-width: 100%;
}

.warningMargin {
  margin-bottom: 1rem;
}

.modal {
  position: absolute;
  right: 50%;
  top: 30%;
  transform: translate(50%, 0%);
  width: 600px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
}

.button {
  float: right;

  background-color: var(--color-button-white-bg);
  border: 2px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  height: 32px;
  width: 94px;
  font-size: 15px !important;
  font-weight: bold;

  margin-left: 22px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.refundBtn {
  background-color: var(--color-button-delete);
  border-color: var(--color-button-delete-border);
  color: var(--color-button-text);
  margin-right: 7px;
}

.refundBtn:hover {
  background-color: var(--color-button-delete-hover) !important;
}

.button:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.label {
  padding-right: 10px;
  margin-bottom: 50px;
}
.confirm {
  margin-top: 20px;
  font-weight: 500;
}

.danger {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #f8d7da;
  /* Light red background */
  color: #721c24;
  /* Dark red text */
  text-align: center;
}

.goToButton {
  float: right;

  /* background-color: var(--color-button-white-bg); */
  /* border: 2px solid var(--color-button-white-border); */
  /* color: var(--color-button-white-text); */
  border-radius: 8px;
  height: 32px;
  width: 128px;
  font-size: 15px !important;
  font-weight: bold;

  margin-left: 22px;
  margin-top: 20px;
  margin-bottom: 15px;

  
  background-color: var(--color-button-default);
  border-color: var(--color-button-default);
  color: var(--color-button-text);
}

.goToButton:hover {
  cursor: pointer;
  background-color: var(--color-button-default-hover);
}



@media screen and (min-width: 700px) {
  .containerEvent {
    display: flex;
  }

  .containerDateSeparator {
    text-align: unset;
  }

  .ticket {
    display: flex;
    gap: 0.5em;
  }

  .ticketId,
  .ticketPrice,
  .ticketType {
    display: flex;
    align-items: center;
    margin-right: 1em;
  }
}

.modal {
  position: absolute;
  right: 50%;
  top: 20%;
  transform: translate(50%, 0%);
  width: 600px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
}

.buttonContainer {
  margin-right: 12px;
}

.button {
  float: right;

  background-color: var(--color-button-white-bg);
  border: 2px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  height: 32px;
  width: 94px;
  font-size: 15px !important;
  font-weight: bold;

  margin-left: 22px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.disabled {
  cursor: not-allowed !important;
  background-color: var(--color-button-bg-disabled) !important;
  border: 2px solid var(--color-button-white-border);
  color: var(--color-button-disabled);
}

.updateBtn {
  background-color: var(--color-button-default);
  border-color: var(--color-button-default);
  color: var(--color-button-text);
}

.updateBtn:hover {
  cursor: pointer;
  background-color: var(--color-button-default-hover);
}

.closeButton:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.label {
  padding-right: 10px;
  margin-bottom: 50px;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 400px;
  overflow: auto;
  margin-bottom: 12px;
}
.listContainerA {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 24px;
  margin-left: 24px;
}
.listContainerB {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 24px;
  margin-right: 36px;
}
.success {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #d4edda;
  /* Light green background */
  color: #155724;
  /* Dark green text */
  text-align: center;
}

.danger {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #f8d7da;
  /* Light red background */
  color: #721c24;
  /* Dark red text */
  text-align: center;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.listHeader {
  font-weight: 500;
}

.checkbox {
  display: flex;
  flex-direction: row;
  margin-left: 24px;
}

.checkboxText {
  margin-left: 6px;
}
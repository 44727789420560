.container {
  display: flex;
  flex-direction: column;
}

.iboContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 7.5px;
  padding-bottom: 75px;
  border-top: 1px solid var(--color-button-default-border);
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ibo {
  width: 40%;
}

.event {
  text-transform: uppercase;
  width: 40%;
  height: calc(15px + (0.332 * 15px) + (0.334667 * 15px));
  margin-top: 7.5px;
  padding-bottom: 75px;
}

.eventName {
  font-weight: bold;
}

.event p {
  width: fit-content;
  padding: 0 1px;
}

.container > .has-ticket,
.list-container ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 65px;
  width: 100%;
}

.container > .has-ticket {
  width: 60%;
}
.tickets {
  min-width: 270px;
  padding-right: 30px;
}
.ticket-container,
.add-ticket-checkbox,
.add-ticket-checkbox-alt,
.add-ticket-checkbox-alt2,
.accomodation-checkbox {
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: left;
  min-width: 150px;
}

.ticket-container .accommodation {
  flex-direction: column-reverse;
}

.ticket-container {
  /* border-bottom: 1px solid black; */
  /* padding-bottom: 12px; */
}

.add-ticket-checkbox {
  gap: 8px;
  margin-top: 10px;
  margin-left: 26%; /* Doesn't line up with other checkbox or Select because of differing styles. Find way to match up*/
}

.add-ticket-checkbox-alt {
  gap: 8px;
  margin-top: -10px;
  margin-left: 26%;
}

.add-ticket-checkbox-alt2 {
  gap: 8px;
  margin-top: 10px;
  margin-left: 78.7%;
}

.accomodation-checkbox {
  gap: 8px;
  margin-top: 7px;
}

.accommodation {
  margin-top: 5px;
}

.accommodation .list {
  margin-left: 20px;
  list-style-type: disc;
}

.list .light::before {
  content: "•";
  display: inline-block;
  margin-right: 8px;
}

.list .light {
  font-weight: lighter;
  font-size: 12px;
}
.ticket-container {
  display: flex;
  flex-direction: row;
  /* Adjust if you want them side-by-side */
  align-items: flex-start;
  /* Aligns children to the top */
}
.ticket-container > .number-input-container {
  min-width: 26%;
  display: flex;
  /* padding-right: 24px; */
  align-items: flex-start;
}

.ticket-container input[type="number"] {
  line-height: 1.5;
  border: 1px solid var(--color-border);
  text-align: center;
  width: calc(1em + 8px);
}

.ticket-container input[type="number"]::-webkit-outer-spin-button,
.ticket-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ticket-container input[type="number"]:focus {
  outline: none;
}

.ticket-container p {
  /* width: 15%; */
  /* text-align: end; */
  font-weight: bold;
  font-size: smaller;
  margin-top: -20px;
}

.light {
  font-weight: lighter;
  font-size: smaller;
}

.checkbox {
  transition: all 0.3s ease-out;
}

.hidden {
  display: flex;
  opacity: 0;
  user-select: 0;
}

.collapse {
  display: none;
}

.tickets li,
.accommodation li {
  line-height: 1.8em !important;
}

.tickets button {
  margin-bottom: 2px !important;
}

.tickets li:hover,
.accommodation li:hover {
  background-color: var(--color-form-bg) !important;
}

.error {
  color: var(--color-h);
  font-weight: lighter;
  font-size: smaller;
}

/* Transitions */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: all 0.3s ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.price-remove-container {
  min-width: 75px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.removeContainer {
  width: 60px;
  margin-left: 24px;
}

.remove {
  background-color: var(--color-button-remove);
  border: none;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invisible {
  display: none;
}

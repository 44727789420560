.container {
  background-color: var(--color-form-bg);
  padding: 0.5em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 1.2em;
  margin-left: 0.5em;
}

.controls {
  display: flex;
  align-items: center;
}

.controls > * {
  margin-left: 0.5em;
}
